import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { environment } from "./../../../environments/environment";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	notificationData = new Subject<any>();
	notificationCount = new Subject<any>();
	private token: string;
	private serverUrl = environment.serverUrl;
	mySubject = new Subject<any>();
	activeChat = new Subject<any>();
	constructor(private http: HttpClient, private router: Router) {
		setInterval(() => {
			if (localStorage.getItem("user_id")) {
				this.ApiGET("/notification/getNotification?userId=" + localStorage.getItem("user_id")).subscribe((res) => {
					this.notificationData.next(res.data);
					this.notificationCount.next(res.count);
				});
				// this.registerNotifications("global","","hello","testing","testing description")
			}
		}, 5000);
	}

	public registerNotifications(forType, forId, name, type, discription) {
		let data = {
			user_id: localStorage.getItem("user_id"),
			for_type: forType,
			for_id: forId,
			viewed_by: [],
			logged_at: new Date(),
			notification_name: name,
			notification_type: type,
			description: discription,
		};
		this.ApiPOST("/notification/setNotification", data).subscribe((res) => {
			if (!res.status) {
				console.log("failed to create the notification");
			}
		});
	}

	public updateNotifications(data) {
		this.ApiPOST("/notification/updateViewed", data).subscribe((res) => {
			if (!res.status) {
				console.log("failed to create the notification");
			}
		});
	}

	public deleteNotifications(data) {
		this.ApiPOST("/user/deletedViewed", data).subscribe((res) => {
			if (!res.status) {
				console.log("failed to delete");
			}
		});
	}

	public ApiGET(endpoint, params = {}) {
		params = params != undefined ? params : {};
		this.token = localStorage.getItem("thunder_token");
		const url = `${this.serverUrl}` + "/" + endpoint;
		
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				authorization: "Bearer " + this.token,
			}),
			params: params,
		};
		return this.http.get(url, httpOptions).pipe(
			tap(
				(res: any) => {
					return res;
				},
				(err: any) => {
					if (err.status == 401) {
						this.router.navigate(["/login"]);
					} else if (err.status == 200) {
						return err;
					} else {
						// this.router.navigate(['/serverErr'])
						return null;
					}
				}
			)
		);
	}

	public paymentPost(endpoint, payload) {
		const httpOptions = {
			headers: new HttpHeaders({
				token: payload,
				"Content-Type": "application/x-www-form-urlencoded",
				"Access-Control-Allow-Origin": "*",
			}),
		};
		return this.http.post(endpoint, {token:payload}, httpOptions);
	}

	public ApiPOST(endpoint, payload) {
		this.token = localStorage.getItem("thunder_token");
		const url = `${this.serverUrl}` + "/" + endpoint;
		const httpOptions = {
			headers: new HttpHeaders({
				authorization: "Bearer " + this.token,
			}),
		};
		return this.http.post(url, payload, httpOptions).pipe(
			tap(
				(res: any) => {
					return res;
				},
				(err: any) => {
					if (err.status == 401) {
						this.router.navigate(["/login"]);
					} else {
						// this.router.navigate(['/serverErr'])
					}
					return null;
				}
			)
		);
	}

	public recaptchaVerify(token) {
		const url = "https://www.google.com/recaptcha/api/siteverify";
		return this.http.post(url, { sitekey: "6Lc-8sgcAAAAAK5Mgm9f42SozkxDdGsQW90b7W49", response: token }).pipe(
			tap(
				(res: any) => {
					return res;
				},
				(err: any) => {
					if (err.status == 401) {
						this.router.navigate(["/login"]);
					} else {
						// this.router.navigate(['/serverErr'])
					}
					return null;
				}
			)
		);
	}

	public ApiPUT(endpoint, payload) {
		this.token = localStorage.getItem("thunder_token");
		const url = `${this.serverUrl}` + "/" + endpoint;
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				authorization: "Bearer " + this.token,
			}),
		};

		return this.http.put(url, payload, httpOptions).pipe(
			tap(
				(res: any) => {
					return res;
				},
				(err: any) => {
					if (err.status == 401) {
						this.router.navigate(["/login"]);
					} else {
						// this.router.navigate(['/serverErr'])
					}
					return null;
				}
			)
		);
	}

	public ApiDELETE(endpoint) {
		this.token = localStorage.getItem("thunder_token");
		const url = `${this.serverUrl}` + "/" + endpoint;
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				authorization: "Bearer " + this.token,
			}),
		};

		return this.http.delete(url, httpOptions).pipe(
			tap(
				(res: any) => {
					return res;
				},
				(err: any) => {
					if (err.status == 401) {
						this.router.navigate(["/login"]);
					} else {
						// this.router.navigate(['/serverErr'])
					}
					return null;
				}
			)
		);
	}

	public getAddress(latlng) {
		const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=AIzaSyAdFTZM0RkHUHgga9nb1ASN6JeaBowN8mI`;

		return this.http.get(url).pipe(
			tap(
				(res: any) => {
					return res;
				},
				(err: any) => {
					if (err.status == 401) {
						this.router.navigate(["/login"]);
					} else if (err.status == 200) {
						return err;
					} else {
						// this.router.navigate(['/serverErr'])
						return null;
					}
				}
			)
		);
	}

	public getLatlng(address) {
		const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAdFTZM0RkHUHgga9nb1ASN6JeaBowN8mI`;

		return this.http.get(url).pipe(
			tap(
				(res: any) => {
					return res;
				},
				(err: any) => {
					if (err.status == 401) {
						this.router.navigate(["/login"]);
					} else if (err.status == 200) {
						return err;
					} else {
						// this.router.navigate(['/serverErr'])
						return null;
					}
				}
			)
		);
	}

	getAppointmentBaseData() {
		return this.ApiGET(`appointment/base/`);
	}

	importStudent(data){
		return this.ApiPOST('bookstore/importbooks',data);
	}

	importHoliday(data){
		return this.ApiPOST('holiday/importHoliday',data);
	}

	ListApi(api_url) {
		return this.ApiGET("hr/admin/" + api_url + "/list", {});  
	}
}
